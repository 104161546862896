import moment from "moment";
import firebase from "firebase/compat/app";
import {
  storageRef,
  productsCollection,
  productsGlossaryCollection,
  productsTestCollection,
  productsDonatedCollection,
  logsCollection,
  usersCollection,
  productsRemovedCollection,
  donationsCollection,
} from "../../main";
import { v4 as uuidv4 } from "uuid";
import "firebase/compat/database";

export const getDateColor = (expirationDate) => {
  if (expirationDate) {
    const date = new Date(expirationDate);
    const now = new Date();
    if (date < now) {
      return { color: "red" };
    } else if (date < now.setDate(now.getDate() + 30)) {
      return { color: "orange" };
    }
  }
};
export const isNumber = (evt) => {
  evt = evt ? evt : window.event;
  var charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode >= 48 && charCode <= 57) {
    return true;
  } else {
    evt.preventDefault();
  }
};
export const isDecimal = (evt) => {
  evt = evt ? evt : window.event;
  var charCode = evt.which ? evt.which : evt.keyCode;
  if ((charCode >= 48 && charCode <= 57) || charCode == 46 || charCode == 44) {
    return true;
  } else {
    evt.preventDefault();
  }
};
export const checkOwner = (item) => {
  if (item) return item.uid === localStorage.getItem("uid");
  else return false;
};
export async function sendOnline(product) {
  this.product = product;
  this.product["productState"] = "online";
  if (this.product["check"] != undefined) {
    delete this.product["check"];
  }
  if (this.product["online"] != undefined) {
    delete this.product["online"];
  }
  await this.updateObject();
}

export async function manageDonation(products) {
  this.productsExpiredSoon.forEach(async product => {
    let editedIndex = products.indexOf(product);

    //Save new quantity product or remove from list if quantity = 0
    if(parseInt(product.quantityPublish) < product.quantity) {
      product.quantity = parseInt(product.quantityPublish);
    }
    
    //Make a deep copy of product to save
    this.product =  Object.assign({}, product);

    if(product.quantityPublish > 0) {
      this.product.expireSoon = false;
      await this.updateObject(true, editedIndex);
    } else {
      productsCollection
        .doc(this.product["id"])
        .delete()
        .then(() => {
          this.products.splice(this.editedIndex, 1);
        })
        .catch(() => {
          this.message = "Erreur lors de la suppression du produit";
          this.snackbar = true;
        });
    }
    
    // save into log deleted products
    if(product.quantityDelete > 0) {
      product.quantity = product.quantityDelete;
      
      product["reason"] = "notDonated";
      product['created_at'] = firebase.firestore.FieldValue.serverTimestamp();
      product['timestamp'] = firebase.firestore.FieldValue.serverTimestamp();

      await logsCollection.add({
        event: "deleteNotDonated",
        product: product,
        datetime: moment().format("YYYY/MM/DD HH:mm:ss"),
        uid: localStorage.getItem("uid"),
      });
      productsRemovedCollection.doc(this.product.id).set(product);
    }
  });

  addToDonationCart(this.productsExpiredSoon);
}

export async function donateProduct(index, quantity) {

  let product = Object.assign({}, this.product);

  product.quantityDon = quantity;

  product["reason"] = "donated";
  product['created_at'] = firebase.firestore.FieldValue.serverTimestamp();
  product['timestamp'] = firebase.firestore.FieldValue.serverTimestamp();

  await logsCollection.add({
    event: "donate",
    product: product,
    datetime: moment().format("YYYY/MM/DD HH:mm:ss"),
    uid: localStorage.getItem("uid"),
  }).then(() => {
    productsRemovedCollection.doc(product.id).set(product).then(() => {
      if(localStorage.getItem('uid') === this.uidTest) {
        productsTestCollection
        .doc(product.id)
        .delete()
        .then(() => {
          this.products.splice(index, 1);
          addToDonationCart([product]);
        })
        .catch(() => {
          this.message = "Erreur lors de la donation du produit";
          this.snackbar = true;
        });
      } else {
        productsCollection
        .doc(product.id)
        .delete()
        .then(() => {
          this.products.splice(index, 1);
          addToDonationCart([product]);
        })
        .catch(() => {
          this.message = "Erreur lors de la donation du produit";
          this.snackbar = true;
        });
      }
    });
  });
}

export async function addToDonationCart(productToDonate) {
  let uid = productToDonate[0].uid;

  //Retrieve last donation cart of user
  await donationsCollection
  .where("donorId", "==", uid)
  .where("status", "==", "inProgress")
  .get()
  .then(async (donations) => {

    await usersCollection
      .doc(uid)
      .get()
      .then(async (pharmacyData) => {
        let pharmacy = pharmacyData.data();
        let donation;
        let id;

        for (let i = 0; i < productToDonate.length; i++) {
          let product =  productToDonate[i];

          if(product.quantityDon > 0) {
            let donationProduct = {
              ean: product.ean,
              name: product.name,
              brandName: product.brandName,
              price: product.price,
              quantity: parseInt(product.quantityDon),
              expirationDate: product.expirationDate
            };

          
            if(donations.docs.length > 0) {
              donation = donations.docs[0].data();
              id = donations.docs[0].id;
            } else {
              donation = {
                city: pharmacy.city,
                donationId: moment().format("DDMM") + Math.floor(Math.random() * 1000000).toString(),
                donorId: product.uid,
                pharmacyAddress: pharmacy.address,
                pharmacyMail: pharmacy.mail,
                pharmacyName: pharmacy.pharmacyName,
                pharmacyPhone: pharmacy.phone,
                postalCode: pharmacy.postalCode,
                products: [],
                status: "inProgress",
                taxReduction: 0,
                totalPrice: 0
              };
            }

            donation.products.push(donationProduct);
            donation.dateUpdate = moment().format("YYYY/MM/DD HH:mm:ss");

            //Calculate totalPrice
            let total = 0;
            donation.products.forEach((product) => {
              total += product.price * product.quantity;
            });
            donation.totalPrice = Math.round(total * 100) / 100;

            //Calculate tax reduction
            donation.taxReduction = Math.round(total * 66) / 100;
          }
        }
               
        if(id) {
          let ref = donationsCollection.doc(id);
          ref.update(donation);
        } else {
          let ref = donationsCollection.doc();
          ref.set(donation);
        }
      });
  });
}

export async function getDataFromEan(ean) {
  if (ean != undefined && ean != "") {
    let product = await productsGlossaryCollection
      .where("ean", "==", ean)
      .get()
      .then(async (querySnapshot) => {
        if (!querySnapshot.empty) {
          let product = Object.assign({}, querySnapshot.docs[0].data());

          // Set max length to 41 char
          let name = product.name.substring(0, Math.min(product.name.length, 41));
          // Put the string into an array to remove last word to assure name doesnt and with and truncated word
          name = name.split(" ");
          name.pop();
          // Join array to re construct the name
          product.name = name.join(" ");
          product["fromInternalDatabase"] = true

          let url = "https://www.idfmoteurs.com/images/pas-image-disponible.png";

          try {
            url = await storageRef
              .child(`productsImagesGlossary/${ean}.png`)
              .getDownloadURL();
          } catch (e) {
            console.log(e);
          }

          product["imgUrl"] = url;

          switch (querySnapshot.docs[0].data()["category"]) {
            case "soins":
            case "solaires":
              product["categoryProduct"] = "Cosmétiques";
              break;
            case "santé":
              product["categoryProduct"] = "Santé";
              break;
            case "hygiene":
              product["categoryProduct"] = "Hygiène";
              break;
            case "cheveux":
              product["categoryProduct"] = "Cheveux";
              break;
            case "bebe-et-maman":
              product["categoryProduct"] = "Bébé";
              break;
            case "homme":
              product["categoryProduct"] = "Homme";
              break;
            case "parfum-et-beaute":
              product["categoryProduct"] = "Maquillage et accessoires";
              break;
            case "complements-alimentaires":
              product["categoryProduct"] = "Compléments alimentaires";
              break;
            case "nutrition":
              product["categoryProduct"] = "Nutrition";
              break;
            case "aromatherapie":
              product["categoryProduct"] = "Aromathérapie";
              break;
            case "medecine-naturelle":
              product["categoryProduct"] = "Phytothérapie";
              break;
            case "sport":
              product["categoryProduct"] = "Sport";
              break;
            case "minceur":
              product["categoryProduct"] = "Minceur";
              break;
            case "veterinaire":
              product["categoryProduct"] = "Vétérinaire";
              break;
            case "sexualite":
              product["categoryProduct"] = "Sexualité";
              break;
            default:
              product["categoryProduct"] = "";
          }
          return product;
        } else {
          alert(
            "Le code EAN saisi n'est pas référencé dans notre base de données, merci de compléter le formulaire"
          );
          return (-1);
        }
      });
    delete product["img"];
    return product;
  } else {
    alert("Merci de saisir un code EAN");
  }
  return { ean: ean };
}

async function getProductState(product) {
    // Set automatically online if all required information are set and not product of category "Santé"
    if(
      (product["categoryProduct"] && product["categoryProduct"] != "Santé") &&
      product["fromInternalDatabase"] === true &&
      product["img"] &&
      product["name"] && 
      product["quantity"] && 
      product["brandName"] && 
      product["ean"] &&
      product["shape"] &&
      product["price"] &&
      product["pourcentageReduction"] &&
      product["presentation"] &&
      product["contenanceType"] &&
      product["contenantType"] &&
      product["capacity"] &&
      product["packageId"] &&
      product["ingredients"] &&
      product["wayToUse"] &&
      product["description"] 
    ) {
      return "online";
    } else return "unverified";
}

//CRUD -----------------------------------------

export async function getProductsList() {
  try {
    switch (localStorage.getItem('uid')) {
      case this.uidAdmin:
        productsCollection
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              let product = doc.data();
              product.id = doc.id;
              product.name = product.name.trim();
              usersCollection
                .doc(product.uid)
                .get()
                .then((doc) => {
                  if (doc.exists) {
                    product.mail = doc.data()["mail"];
                    product.city = doc.data()["city"];
                    if(product.ddmDate) {
                      product.ddmSaisi = moment(product.ddmDate).format('YYYY-MM');
                    }
                    if(product.expirationDate) {
                      product.expirationSaisi = moment(product.expirationDate).format('YYYY-MM');
                    }
                    if(product.productState == 'online') {
                      if(product.expireSoon == true) {
                        product.state = 'Expire bientôt';
                      } else {
                        product.state = 'En ligne';
                      }
                    }
                  }
                  Object.assign(product, {
                    imgUrl: product.img ? product.img : "",
                    img: [],
                  });
                  this.products.push(product);
                });
            });
          });
        break;
      case this.uidTest:
      case this.uidDemo:
        productsTestCollection
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              let product = doc.data();
              if (product['uid'] === localStorage.getItem('uid')) {
                product.id = doc.id;
                product.name = product.name.trim();
                usersCollection
                  .doc(product.uid)
                  .get()
                  .then((doc) => {
                    if (doc.exists) {
                      product.mail = doc.data()["mail"];
                      product.city = doc.data()["city"];
                      if(product.ddmDate) {
                        product.ddmSaisi = moment(product.ddmDate).format('YYYY-MM');
                      }
                      if(product.expirationDate) {
                        product.expirationSaisi = moment(product.expirationDate).format('YYYY-MM');
                      }
                      if(product.productState == 'online') {
                        if(product.expireSoon == true) {
                          product.state = 'Expire bientôt';
                        } else {
                          product.state = 'En ligne';
                        }
                      }
                      
                    }
                    Object.assign(product, {
                      imgUrl: product.img ? product.img : "",
                      img: [],
                    });
                    this.products.push(product);

                    if(product.expireSoon && product.productState === "online") {
                      product.quantityDon = product.quantity;
                      product.quantityPublish = 0;
                      product.quantityDelete = 0;
                      this.productsExpiredSoon.push(product);
                      this.dialogDonation = true;
                    }
                  });
              }
            });
          });
        break;
      default:
        productsCollection
          .where("uid", "==", localStorage.getItem("uid"))
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              let product = doc.data();
              if (product.visible != false) {
                product.id = doc.id;
                product.name = product.name.trim();
                if(product.ddmDate) {
                  product.ddmSaisi = moment(product.ddmDate).format('YYYY-MM');
                }
                if(product.expirationDate) {
                  product.expirationSaisi = moment(product.expirationDate).format('YYYY-MM');
                }
                if(product.productState == 'online') {
                  if(product.expireSoon == true) {
                    product.state = 'Expire bientôt';
                  } else {
                    product.state = 'En ligne';
                  }
                }

                Object.assign(product, {
                  imgUrl: product.img ? product.img : "",
                  img: [],
                });
                Object.assign(this.product, { id: product.id });
                this.products.push(product);

                if(product.expireSoon && product.productState === "online") {
                  product.quantityDon = product.quantity;
                  product.quantityPublish = 0;
                  product.quantityDelete = 0;
                  this.productsExpiredSoon.push(product);
                  this.dialogDonation = true;
                }
              }
            });
          });
    }
  } catch (error) {
    this.message = error.message;
    this.snackbar = true;
  }
}

export async function getProductsDonatedList() {
  try {
    if(localStorage.getItem('uid') == this.uidAdmin) {
      productsDonatedCollection
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let product = doc.data();
          if (product.visible != false) {
            product.id = doc.id;
            product.name = product.name.trim();
            usersCollection
            .doc(product.uid)
            .get()
            .then((doc) => {
              if (doc.exists) {
                product.mail = doc.data()["mail"];
                product.city = doc.data()["city"];
              }
              Object.assign(product, {
                imgUrl: product.img ? product.img : "",
                img: [],
              });
            });
            Object.assign(product, {
              imgUrl: product.img ? product.img : "",
              img: [],
            });
            Object.assign(this.product, { id: product.id });
            this.products.push(product);
          }
        });
      });

    } else {
      productsDonatedCollection
      .where("uid", "==", localStorage.getItem("uid"))
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let product = doc.data();
          if (product.visible != false) {
            product.id = doc.id;
            product.name = product.name.trim();
            usersCollection
            .doc(product.uid)
            .get()
            .then((doc) => {
              if (doc.exists) {
                product.mail = doc.data()["mail"];
                product.city = doc.data()["city"];
              }
              Object.assign(product, {
                imgUrl: product.img ? product.img : "",
                img: [],
              });
              this.products.push(product);
            });
            Object.assign(product, {
              imgUrl: product.img ? product.img : "",
              img: [],
            });
            Object.assign(this.product, { id: product.id });
            this.products.push(product);
          }
        });
      });
    }
  } catch (error) {
    this.message = error.message;
    this.snackbar = true;
  }
}


export async function deleteObj() {
  let product = Object.assign({}, this.product);
  product["img"] = product["imgUrl"];
  delete product["imgUrl"];

  if (this.product.imgUrl != "" && this.product.imgUrl != null) {
    try {
      await storageRef
        .child(`images/${this.product["uid"]}/${this.product["id"]}`)
        .delete();
    } catch (e) {
      console.log(e);
    }
  }

  if (localStorage.getItem('uid') === this.uidDemo || localStorage.getItem('uid') === this.uidTest) {
    productsTestCollection.doc(product.id).delete().then(() => {
      this.message = "Produit supprimé de la liste";
      this.snackbar = true;
      this.products.splice(this.editedIndex, 1);
      this.closeDelete();
    });
  } else {
    product["reason"] = "deleted";
    product['created_at'] = firebase.firestore.FieldValue.serverTimestamp();
    product['timestamp'] = firebase.firestore.FieldValue.serverTimestamp();

    await logsCollection.add({
      event: "delete",
      product: product,
      datetime: moment().format("YYYY/MM/DD HH:mm:ss"),
      uid: localStorage.getItem("uid"),
    });
    productsRemovedCollection.doc(this.product.id).set(product).then(() => {
      productsCollection
        .doc(this.product["id"])
        .delete()
        .then(() => {
          this.message = "Produit supprimé";
          this.snackbar = true;
          this.products.splice(this.editedIndex, 1);
          this.closeDelete();
        })
        .catch(() => {
          this.message = "Erreur lors de la suppression du produit";
          this.snackbar = true;
        });
    });
  }
  this.loading = false;
}

export async function updateObject(fromManageDonation, editedIndex) {
  try {
    this.isUploading = true;

    this.product["price"] = this.getNewPrice;
    this.product["name"] = this.product["name"].trim();
    this.product['name'] = this.product['name'][0].toUpperCase() + this.product['name'].slice(1).toLowerCase();
    this.product["brandName"] = this.product["brandName"].trim();
    this.product['brandName'] = this.product['brandName'][0].toUpperCase() + this.product['brandName'].slice(1).toLowerCase();

    if( this.product['ingredients']) {
      this.product['ingredients'] = this.product['ingredients'][0].toUpperCase() + this.product['ingredients'].slice(1).toLowerCase();
    }

    if(this.product["expiration"]){
      this.product["expirationDate"] = moment(this.product["expirationSaisi"]).format('YYYY-MM-DD');
    } else {
      this.product["expirationDate"] = null;
    }

    if(this.product["ddm"]) {
      this.product["ddmDate"] = moment(this.product["ddmSaisi"]).format('YYYY-MM-DD');
    } else {
      this.product["ddmDate"] = null;
    }

    if(fromManageDonation == true) {
      //If product expire in less then a month, place status to expiredSoon
      let expirationDate = moment( this.product.expirationDate, 'YYYY-MM-DD');
      let diff = moment().diff(expirationDate, 'days');
      if (diff >= -30) {
        this.product["expireSoon"] = true;
      } else { 
        this.product["expireSoon"] = false;
      }
      }

    //Add a tag to know last modifier origin
    if(localStorage.getItem("uid") == this.uidAdmin) {
      this.product["lastUpdate"] = "admin";
    } else {
      this.product["lastUpdate"] = "user";
    }

    if(this.product.productState == 'online') {
      if(this.product.expireSoon == true) {
        this.product.state = 'Expire bientôt';
      } else {
        this.product.state = 'En ligne';
      }
    }

    // Remove useless data property
    this.$delete(this.product, 'quantityPublish');
    this.$delete(this.product, 'quantityDelete');
    this.$delete(this.product, 'quantityDon');

    let fbProduct = Object.assign({}, this.product);
    
    fbProduct["img"] = fbProduct["imgUrl"];
    delete fbProduct["imgUrl"];

    // Set picture automatically from firebase storage
    if (this.product.img != "" && this.product.img != null) {
      try {
        await storageRef
          .child(`images/${this.product["uid"]}/${this.product["id"]}`)
          .delete();
      } catch (e) {
        console.log(e);
      }

      try {
        await storageRef
          .child(`productsImagesGlossary/${this.product.ean}.png`)
          .delete();
      } catch (e) {
        console.log(e);
      }

      try {
        await storageRef
        .child(`productsImagesGlossary/${this.product.ean}.png`)
        .put(this.product.img);
      } catch (e) {
        console.log(e);
      }
    }
    
    try {
      let url = await storageRef
        .child(`productsImagesGlossary/${this.product.ean}.png`).getDownloadURL();
        fbProduct.img = url;
    } catch (e) {
      if (e == null) console.log(e);
    }


    if (this.uidAdmin != localStorage.getItem("uid")) {
      fbProduct["datetime"] = moment().format("YYYY/MM/DD HH:mm:ss");
    }
    fbProduct['timestamp'] = firebase.firestore.FieldValue.serverTimestamp();

    delete fbProduct["mail"];
    delete fbProduct["state"];
    delete fbProduct["expirationSaisi"];
    delete fbProduct["ddmSaisi"];

    if (fbProduct["uid"] != this.uidAdmin && fbProduct["uid"] != this.uidDemo && fbProduct["uid"] != this.uidTest) {
      await logsCollection
        .add({
          event: "update",
          product: fbProduct,
          datetime: moment().format("YYYY/MM/DD HH:mm:ss"),
          uid: localStorage.getItem("uid"),
        })
        .catch((e) => console.log(e));
    }

    if (fbProduct["uid"] != this.uidDemo && fbProduct["uid"] != this.uidTest) {
      productsCollection
        .doc(this.product["id"])
        .update(fbProduct)
        .then(() => {
          this.product.imgUrl = fbProduct.img;
          this.message = "Produit modifié";
          this.snackbar = true;
          if(fromManageDonation) {
            Object.assign(this.products[editedIndex], this.product);
          } else {
            this.close();
            Object.assign(this.products[this.editedIndex], this.product);
          }
        })
        .catch((e) => {
          console.log(e);
          this.message = "Erreur lors de la modification du produit";
          this.snackbar = true;
        });
    }
    else {
      productsTestCollection
        .doc(this.product["id"])
        .update(fbProduct)
        .then(() => {
          this.product.imgUrl = fbProduct.img;
          if(fromManageDonation) {
            Object.assign(this.products[editedIndex], this.product);
          } else {
            Object.assign(this.products[this.editedIndex], this.product);
          }
          Object.assign(this.products[this.editedIndex], this.product);
          this.message = "Produit modifié";
          this.snackbar = true;
          if(this.dialog || fromManageDonation) {
            this.close();
          }
        })
        .catch((e) => {
          console.log(e);
          this.message = "Erreur lors de la modification du produit";
          this.snackbar = true;
        });
    }

    this.loading = false;
  } catch (error) {
    this.message = "Erreur lors de la modification du produit";
    console.log(error);
    this.snackbar = true;
  }
  this.loading = false;
}

export async function sendObject() {
  if (this.$refs.form.validate())
    if (!this.isUploading) {
      this.isUploading = true;
      try {
        let myId = uuidv4();
        let url = "";

        try {
          url = await storageRef
            .child(`productsImagesGlossary/${this.product.ean}.png`)
            .getDownloadURL();
        } catch (e) {
          console.log(e);
        }
        
        this.product["name"] = this.product["name"].trim();
        this.product['name'] = this.product['name'][0].toUpperCase() + this.product['name'].slice(1).toLowerCase();
        this.product["brandName"] = this.product["brandName"].trim();
        this.product['brandName'] = this.product['brandName'][0].toUpperCase() + this.product['brandName'].slice(1).toLowerCase();
        this.product["uid"] = localStorage.getItem("uid");
        this.product["id"] = myId;
        this.product["imgUrl"] = url;
        this.product["img"] = url;
        this.product["price"] = this.getNewPrice;
    
        if(this.product["expiration"]) {
          this.product["expirationDate"] = moment(this.product["expirationSaisi"]).format('YYYY-MM-DD')
        }
        if(this.product["ddm"]) {
          this.product["ddmDate"] = moment(this.product["ddmSaisi"]).format('YYYY-MM-DD')
        }

        this.product["productState"] = await getProductState(this.product);
        if(this.product.productState == 'online') {
          if(this.product.expireSoon == true) {
            this.product.state = 'Expire bientôt';
          } else {
            this.product.state = 'En ligne';
          }
        }

        this.product["datetime"] = moment().format("YYYY/MM/DD HH:mm:ss");

        //If product expire in less then a month, place status to expiredSoon
        let expirationDate = moment( this.product.expirationDate, 'YYYY-MM-DD');
        let diff = moment().diff(expirationDate, 'days');
        if (diff >= -30) {
          this.product["expireSoon"] = true;
        } else { 
          this.product["expireSoon"] = false;
        }

        let product = Object.assign({}, this.product);
        product["img"] = url;

        delete product["imgUrl"];
        delete product["mail"];
        delete product["state"];
        delete product["expirationSaisi"];
        delete product["ddmSaisi"];

        if (product.uid != process.env.VUE_APP_UID_ADMIN) {
          await logsCollection.add({
            event: "send",
            product: product,
            datetime: moment().format("YYYY/MM/DD HH:mm:ss"),
            uid: localStorage.getItem("uid"),
          });
        }

        product["created_at"] = firebase.firestore.FieldValue.serverTimestamp();
        product["timestamp"] = firebase.firestore.FieldValue.serverTimestamp();
        if (
          localStorage.getItem("uid") != this.uidDemo &&
          localStorage.getItem("uid") != this.uidTest
        ) {
          productsCollection
            .doc(myId)
            .set(product)
            .then(() => {
              this.message = "Produit envoyé avec succès";
              this.snackbar = true;
              let productToPush = Object.assign({}, this.product);
              this.products.push(productToPush);
              this.close();
            });
        } else {
          productsTestCollection
            .doc(myId)
            .set(product)
            .then(() => {
              this.message = "Produit envoyé avec succès";
              this.snackbar = true;
              let productToPush = Object.assign({}, this.product);
              this.products.push(productToPush);
              this.close();
            });
        }
      } catch (e) {
        console.log(e);
        this.message = "Le produit n'a pas pu être ajouté";
        this.snackbar = true;
        this.isUploading = false;
      }
    }
  this.loading = false;
}