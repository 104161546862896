<template>
  <nav class="sidebar-style" v-if="loggedIn">
    <div class="sidebar-logo">
      <router-link class="noStyle" to="/home">
        <img src="../assets/img-logo.svg" class="img-logo" />
      </router-link>

      <ul class="sidebar-menu">
        <router-link to="/home" :class="{'sidebar-button-container': true, 'active-tab': currentPage === '/accueil'}">
          <div :class="{'top': currentPage === '/accueil'}"></div>
          <i class="mdi mdi-view-dashboard-outline"></i>
          <span class="sidebar-button-title">Accueil</span>
          <div :class="{'bottom': currentPage === '/accueil'}"></div>
        </router-link>

        <li class="sidebar-section-title">DONNÉES</li>

        <router-link to="/unsold" :class="{'sidebar-button-container': true, 'active-tab': currentPage === '/unsold'}">
          <div :class="{'top': currentPage === '/unsold'}"></div>
          <i class="mdi mdi-pill"></i>
          <span class="sidebar-button-title">Invendus</span>
          <div :class="{'bottom': currentPage === '/unsold'}"></div>
        </router-link>

        <li class="sidebar-section-title">RÉEMPLOI</li>

        <router-link to="/sales" :class="{'sidebar-button-container': true, 'active-tab': currentPage === '/sales'}">
          <div :class="{'top': currentPage === '/sales'}"></div>
          <i class="mdi mdi-cellphone"></i>
          <span class="sidebar-button-title">Ventes</span>
          <div :class="{'bottom': currentPage === '/sales'}"></div>
        </router-link>

        <router-link to="/donations" :class="{'sidebar-button-container': true, 'active-tab': currentPage === '/donations'}">
          <div :class="{'top': currentPage === '/donations'}"></div>
          <i class="mdi mdi-charity"></i>
          <span class="sidebar-button-title">Dons</span>
          <div :class="{'bottom': currentPage === '/donations'}"></div>
        </router-link>

        <li class="sidebar-section-title">PRÉ-GASPI</li>

        <router-link to="/targeting" :class="{'sidebar-button-container': true, 'active-tab': currentPage === '/targeting'}">
          <div :class="{'top': currentPage === '/targeting'}"></div>
          <i class="mdi mdi-target-account"></i>
          <span class="sidebar-button-title">Ciblage</span>
          <div :class="{'bottom': currentPage === '/targeting'}"></div>
        </router-link>

      </ul>
    </div>

    <!-- <div class="version-style">v2.4.1</div> -->
    <img class="img-deco" src="../assets/img-deco.png"/>
  </nav>
</template>

<script>
import firebase from "firebase/compat/app";
export default {
  data() {
    return {
      error: false,
      loggedIn: false,
      currentPage: null
    };
  },
  watch: {
    '$route'(to) {
      this.currentPage = to.path;
    }
  },
  created() {
    this.currentPage = this.$route.path;
  },
  mounted() {
  this.loggedIn = localStorage.getItem("loggedIn");
  this.setupFirebase();
  document.addEventListener('DOMContentLoaded', () => {
    const buttons = document.querySelectorAll('.sidebar-button-app');
    buttons.forEach((button) => {
      button.addEventListener('click', () => {
        // Supprimer la classe active de tous les boutons
        buttons.forEach((btn) => {
          btn.classList.remove('active');
        });

        // Ajouter la classe active uniquement au bouton cliqué
        button.classList.add('active');
      });
    });
  });
},
  methods: {
    setupFirebase() {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          localStorage.setItem("loggedIn", true);
          localStorage.setItem("uid", user.uid);
          this.loggedIn = true;
        } else {
          localStorage.setItem("loggedIn", false);
          localStorage.setItem("uid", "");
          this.loggedIn = false;
        }
      });
    },
    isActive(button) {
    return this.$route.name === button;
    },
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          localStorage.setItem("loggedIn", false);
          localStorage.setItem("uid", "");
          this.$router.push("/login");
          // this.loggedIn = false;
        })
        .catch((err) => {
          console.log(err);
          alert(
            "Une erreur est survenue lors de la connexion, merci de contacter notre assistance technique"
          );
        });
    },
  },
};
</script>

<style scoped>

.sidebar-style {
  position: absolute;
  left: 0;
  width: 12vw;
  display: flex;
  background-color: white;
  height: 100vh;
  margin-right: auto;
  align-items: flex-start;
}

.sidebar-logo {
  width: 100%;
}

.noStyle {
  margin-top: 3vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  color: inherit;
}

.noStyle img {
  width: 80%;
  display: block;
}

.sidebar-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5vh;
  margin-top: 10vh;
  width: 100%;
  color: #3d3c3c;
}

a {
  text-decoration: none;
}

.sidebar-button-container {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 5%;
  width: 100%;
  font-size: 30px;
  color: inherit !important;
}

.sidebar-button-container i {
  padding-right: 1vw;
}

.active-tab {
  background-color: #B5E5DC;
  border-radius: 20px 0 0 20px;
  color: #015C53 !important;
}

.sidebar-button {
  border: none !important;
  background-color: transparent !important;
  transition: none;
  transform: scale(2);
}

.sidebar-button:hover::before {
  background-color: rgba(0, 0, 0, 0.1);
}

.sidebar-button-title {
  font-size: 18px;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  letter-spacing: -0.10px;
  white-space: nowrap;
}


.version-style {
  font-size: 10px;
  text-align: center;
  color: black;
  margin-top: auto;
  margin-bottom: 1px;
  position: absolute;
  bottom: 0;
  left: 3px;
}

.img-deco {
  height: 30%;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  color: #3d3c3c;
}

li:first-child {
  margin-right: auto;
  display: block;
}

ul {
  list-style: none;
}

.top,
.bottom {
  position: absolute;
  height: 30px;
  width: 30px;
  right: 0;
  overflow: hidden;
}

.top {
  top: -30px;
}

.bottom {
  bottom: -30px;
}

.sidebar-section-title {
  font-size: 10px;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  letter-spacing: -0.10px;
  margin-left: -12px;
  color: #808080
}

.top::before,
.bottom::before {
  content: '';
  position: absolute;
  right: 0;
  height: 200%;
  width: 200%;
  border-radius: 100%;
  box-shadow: 10px 10px 5px 100px #B5E5DC;
  z-index: 1;
}

.top::before {
  top: -100%;
}

@media screen and (max-width: 1300px) {
  
  .sidebar-button-container {
    font-size: 22px;
  }

  .sidebar-button-title {
    font-size: 12px;
  }

  .img-deco {
    display: block;
    margin: 0 auto;
  }
}

</style>