<template>
  <div class="login-container">
      <router-link class="logo" to="/home">
      <img src="../../assets/logo.png" class="img-logo" />
      </router-link>
      <v-form class="form-style">
        
              <h2 class="connexion-style mb-2">
                Réinitialisation
              </h2>
    
                  <v-text-field
                    v-model="form.email"
                    :rules="emailRules"
                    :label="form.email ? null : 'E-mail'"
                    required
                    class="v-text-field--outlined email-input"
                    style="margin-top: 22px;"
                  ></v-text-field>
              <div class="mt-12 forgotPassword" @click="$router.push('/login')">
                Se connecter ?
              </div>
                  <v-btn 
                    class="connect-style" 
                    @click="submit">
                    Réinitialiser
                  </v-btn>
        <div class="error animate__animated animate__fadeIn" v-if="error">
          {{ error }}
        </div>
        <footer class="footer-style">
          v2.4.1
          <span class="footer-separator">|</span>
          <span ><a target="_blank" class="legalNotices-style" :href="legalNotices"> Mentions légales </a></span>
          <span class="footer-separator">|</span>
          <span><a target="_blank" class="privacyPolicy-style" :href="privacyPolicy"> Politique de confidentialité </a></span>
        </footer>
      </v-form>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "animate.css";
export default {

  data() {
  return {
    legalNotices: "https://partenaire.keabot.fr/mentions-legales/",
    privacyPolicy: "https://partenaire.keabot.fr/politique-de-confidentialite/",
    form: {
      email: "",
      password: "",
    },
    show: false,
    emailRules: [
      (v) => !!v || "E-mail obligatoire",
      (v) => /.+@.+\..+/.test(v) || "E-mail invalide",
    ],
    passwordRules: [
      (v) => !!v || "Mot de passe obligatoire",
      // v => v.length >= 6 || 'Mot de passe doit contenir au moins 6 caractères',
    ],
    error: null,
    buttonText: "Se connecter",
  };
},

methods: {
  async submit() {
  if(!this.form.email) {
      this.error = "Saisir un E-mail valide";
      return;
  }
    try {
      await firebase
        .auth()
        .sendPasswordResetEmail(this.form.email)
        alert("Un E-mail vous a été envoyé");
        setTimeout(() => {
          this.$router.push("/login");
        }, 2000);
    } catch (err) {
      this.error = "E-mail invalide";
    }
  },
},

};
</script>


<style scoped>

.logo {
  padding: 1%;
}

.logo img {
  width: 10%;
  display: block;
}

.login-container {
  display: flex;
  flex-direction: column;
  background-color: #B5E5DC;
  height: 100vh;
}

.form-style {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2vh;
  width: 100vw;
  padding-top: 20vh;
}

p {
  text-align: center;
}

.connexion-style {
  color: rgba(0, 0, 0, 0.66);
  font-size: 18px;
}

.email-style {
  background-color: #ffffff;
  border-radius: 20x;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  display: inline-block;
  padding: 25px;
  width: 380px;
  height: 50px;
}

.password-style {
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  display: inline-block;
  padding: 25px;
  width: 380px;
  height: 50px;
  border: none;
}

.password-eye >>> .v-icon {
  font-size: 16px;
  margin-top: 30px;
}
.link-small {
  width: auto; 
  display: inline-block; 
}

.connect-style {
  background-color: rgba(253, 216, 42, 1) !important;
  color:rgba(1, 92, 83, 1);
  width: 180px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 15px;
  text-transform: none;
  padding: 3vh 6vw !important;
}

.footer-style {
  position: absolute;
  bottom: 0;
  font-size: 10px;
  left: 0;
  width: 100%;
  margin-top: 30px;
  color: #3d3c3c;
}

.footer-style a {
  color: #3d3c3c;
  text-decoration: none;
}

.footer-style a:hover {
  text-decoration: underline;
}

.footer-separator {
  margin: 5px;
}
.legalNotices-style {
  text-decoration: none;
  color:#000000
}
.privacyPolicy-style {
  text-decoration: none;
  color:#000000
}
.error {
  font-size: 1.2rem;
  width: 100%;
  text-align: center;
  position: fixed;
  bottom: 0;
  -webkit-box-shadow: 0 0 10px #FDD82A;
  -moz-box-shadow: 0 0 10px #FDD82A;
}
h2 {
  color: #015c53;
}

.forgotPassword {
  color: rgba(0, 0, 0, 0.66);
  cursor: pointer;
}
.v-input {
  background-color: white;
  width: 20vw;
  border-radius: 40px;
  padding: 0 1vw;
  height: 7vh !important;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
</style>