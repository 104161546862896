<template>
  <div class="container-bg">
      <div>
          <div class="container-content">

              <div class="payment">
                  <h1>Accès non autorisé</h1>
                  <p></p>
                  <button class="rounded-pill" @click="redirectToStripe">Débloquer l'accès à la fonctionnalité</button>

              </div>

          </div>
      </div>
  </div>
</template>

<script>

import { loadStripe } from '@stripe/stripe-js';
import firebase from "firebase/compat/app";

export default {

data() {
  return {
    amount: 30, // MODIFER ICI PAR LE MONTANT DE LA SOUSCRIPTION A LA FONCTIONNALITE "CIBLAGE"
    stripe: null,
    message: '',
  };
},

async mounted() {
  //this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY_TEST);
  this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY)
},

methods: {
  async redirectToStripe() {
    try {
      const user = firebase.auth().currentUser;
      // POUR TESTER EN LOCAL
      //const response = await fetch('http://localhost:3000/targeting/create-checkout-session', {
      // POUR LA PRODUCTION
      const response = await fetch('https://cloud.keabot.fr:3000/targeting/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ amount: this.amount, uid: user.uid }),
      });
      const session = await response.json();
      console.log(session);
      const { error } = await this.stripe.redirectToCheckout({
        sessionId: session.id,
      });
      if (error) {
        this.message = error.message;
      }
    } catch (error) {
      this.message = error.message;
    }
  }
},
};  
</script>

<style scoped>

.payment {
  height: 92vh !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#card-element {
border: 1px solid #ccc;
padding: 10px;
border-radius: 4px;
}

button {
  padding: 1% 3%;
  margin-top: 2vh;
  height: 5vh !important;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.328);
  color: #015c53;
  background-color: #FDD82A;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center !important;
}


</style>